// import logo from './logo.svg';
// import './App.css';
import {BrowserRouter} from 'react-router-dom'
// import HomePage from './components/IndividualPage/Home/home'
import Pages from './components/IndividualPage/Pages/pages'

function App() {
  return (
    <div>
      <BrowserRouter>
        <Pages />
      </BrowserRouter>


    </div>
  );
}

export default App;
