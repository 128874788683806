import { Suspense,lazy } from 'react'
import {Route} from 'react-router-dom'


// import HomeNew from '../components/IndividualPage/Home/homeNew'
// import Page from '../components/IndividualPage/Page/page'
// import Portfolio from '../components/IndividualPage/Portfolio/portfolio'
// import RedirectPage from '../components/IndividualPage/Redirect/RedirectPage'

// import Counsellor from '../components/IndividualPage/Counsellor/Counsellor'

//Mindfulness
// import AboutMindfulnessIvan from '../App/Mindfulness/Views/About/AboutIvan'
// import MindfulnessHome from '../App/Mindfulness/Views/Home/Home'

// import WhatIsMindful from '../App/Mindfulness/Views/Mindful/WhatMindful/whatmindful'
// import OriginMindful from '../App/Mindfulness/Views/Mindful/History/origin'
// import MindfulProbronoClass from '../App/Mindfulness/Views/Mindful/Class/bronoclass'
// import MindfulWorkshop from '../App/Mindfulness/Views/Mindful/Workshop/workshop'

//import MindfulContact from '../App/Mindfulness/Views/About/AboutIvan'

//Counselling
//import CounselHome from '../App/Counselling/Views/Home/home'

//Engineering
//import EngineHome from '../App/Engineering/Views/Home/home'

//Mock Test Sites
//import MockHome from '../App/Mock/Views/home/home'

//import t_AnimatedText from '../App/Mock/Views/testpages/animatetext/animatetextfade'
//import t_AutoSuggest from '../App/Mock/Views/testpages/autosuggest/autosuggest'
//import t_BasicHTML from '../App/Mock/Views/testpages/basic/basichtml'
//import t_MenuPage from '../App/Mock/Views/testpages/basicmenu/menupage'
//import T_BoxAnimate from '../App/Mock/Views/testpages/boxclick/box'
// import T_BasicDatePicker from '../App/Mock/Views/testpages/datepicker/basicdatepicker'
//import T_ReactTable from '../App/Mock/Views/testpages/table/main'
//import T_LineChart from '../App/Mock/Views/testpages/line-chart/linechart'
//import T_Lottery from '../App/Mock/Views/testpages/lottery/mainlottery'
//import T_LotteryHorse from '../App/Mock/Views/testpages/lottery/mainhorse'
//import T_TicTacToe from '../App/Mock/Views/testpages/tictactoe/index'

// import T_ECommerce from '../App/Mock/Views/testpages/ecommerce/components/home/main'
// import T_ECommerceItems from '../App/Mock/Views/testpages/ecommerce/components/item/item'
// import T_ECommercePayment from '../App/Mock/Views/testpages/ecommerce/components/payment/payment'
// import T_ECommerceThankYou from '../App/Mock/Views/testpages/ecommerce/components/thankyou/thankyou'

//import T_Bank from '../App/Mock/Views/testpages/bank/login'
//import T_BankAccount from '../App/Mock/Views/testpages/bank/account'
//import T_Travel from '../App/Mock/Views/testpages/travel/main'
//import T_TravelResult from '../App/Mock/Views/testpages/travel/result'
//import T_TravelReserve from '../App/Mock/Views/testpages/travel/reserve'
//import T_TravelThankYou from '../App/Mock/Views/testpages/travel/thankyou'

//import T_GoogleSearch from '../App/Mock/Views/testpages/t_googlesearch/t_googlesearch'

const RoutePage = (props)=>{

 
    const Page=lazy(()=>import ('../components/IndividualPage/Page/page'))
    const Portfolio=lazy(()=>import ('../components/IndividualPage/Portfolio/portfolio'))
    const RedirectPage =lazy(()=>import ('../components/IndividualPage/Redirect/RedirectPage'))

    const EngineHome=lazy(()=>import ('../App/Engineering/Views/Home/home'))

    const CounselHome = lazy(()=>import ('../App/Counselling/Views/Home/home'))


    const AboutMindfulnessIvan = lazy(()=>import ('../App/Mindfulness/Views/About/AboutIvan'))
    const MindfulnessHome = lazy(()=>import ('../App/Mindfulness/Views/Home/Home'))
    const WhatIsMindful = lazy(()=>import ('../App/Mindfulness/Views/Mindful/WhatMindful/whatmindful'))
    const OriginMindful = lazy (()=>import ('../App/Mindfulness/Views/Mindful/History/origin'))
    const MindfulProbronoClass = lazy (()=>import ('../App/Mindfulness/Views/Mindful/Class/bronoclass'))
    const MindfulWorkshop = lazy (()=>import('../App/Mindfulness/Views/Mindful/Workshop/workshop'))
    const MindfulContact = lazy(()=>import ('../App/Mindfulness/Views/About/AboutIvan'))

    const MockHome = lazy(()=>import ('../App/Mock/Views/home/home'))

    const t_AnimatedText = lazy (()=>import ('../App/Mock/Views/testpages/animatetext/animatetextfade'))
    const t_AutoSuggest = lazy (()=>import ('../App/Mock/Views/testpages/autosuggest/autosuggest'))
    const t_BasicHTML = lazy (()=>import ('../App/Mock/Views/testpages/basic/basichtml'))
    const t_MenuPage = lazy (()=>import ('../App/Mock/Views/testpages/basicmenu/menupage'))
    const T_BoxAnimate = lazy (()=>import('../App/Mock/Views/testpages/boxclick/box'))
    const T_BasicDatePicker = lazy(()=>import ('../App/Mock/Views/testpages/datepicker/basicdatepicker'))
    const T_ReactTable = lazy (()=>import ('../App/Mock/Views/testpages/table/main'))
    const T_LineChart = lazy (()=>import('../App/Mock/Views/testpages/line-chart/linechart'))
    const T_Lottery = lazy(()=>import('../App/Mock/Views/testpages/lottery/mainlottery'))
    const T_LotteryHorse = lazy(()=>import('../App/Mock/Views/testpages/lottery/mainhorse'))
    const T_TicTacToe = lazy (()=>import ('../App/Mock/Views/testpages/tictactoe/index'))

    const T_Bank =  lazy(()=>import ('../App/Mock/Views/testpages/bank/login'))
    const T_BankAccount = lazy(()=>import ('../App/Mock/Views/testpages/bank/account'))
    const T_Travel = lazy(()=>import ('../App/Mock/Views/testpages/travel/main'))
    const T_TravelResult = lazy (()=>import('../App/Mock/Views/testpages/travel/result') )
    const T_TravelReserve = lazy(()=>import ('../App/Mock/Views/testpages/travel/reserve'))
    const T_TravelThankYou = lazy(()=>import ('../App/Mock/Views/testpages/travel/thankyou'))

    const T_GoogleSearch = lazy(()=>import('../App/Mock/Views/testpages/t_googlesearch/t_googlesearch') )

    // const T_ECommerce = lazy(()=>import ('../App/Mock/Views/testpages/ecommerce/components/home/main'))
    // const T_ECommerceItems = lazy(()=>import ('../App/Mock/Views/testpages/ecommerce/components/item/item'))
    // const T_ECommercePayment = lazy(()=>import ('../App/Mock/Views/testpages/ecommerce/components/payment/payment'))
    // const T_ECommerceThankYou = lazy(()=>import ('../App/Mock/Views/testpages/ecommerce/components/thankyou/thankyou'))




    return(
        <Suspense fallback={<div>Loading Router</div>}>
            <div>

              
                <Route path ='/home' exact component={Page} />
                <Route path = "/" exact component={Page} />
                <Route path="/portfolio" exact component={Portfolio}/>
                <Route path = '/mock' exact component={MockHome}/>


                <Route path="/mindfulness/about1" exact component={AboutMindfulnessIvan}/>
                <Route path="/mindfulness" exact component={MindfulnessHome}/>
                <Route path="/mindfulness/content/whatismindful" exact component={WhatIsMindful}/>
                <Route path="/mindfulness/content/history" exact component={OriginMindful}/>
                <Route path="/mindfulness/content/meetup" exact component={MindfulProbronoClass}/>
                <Route path="/mindfulness/content/workshop" exact component={MindfulWorkshop}/>
                

                <Route path="/mindfulness/contact" exact component={MindfulContact}/>


                <Route path="/counsellor" exact component={CounselHome}/>


                <Route path="/swe" exact component={EngineHome}/>

                <Route path="/mockhtml/t_basictextanimation" exact component={t_AnimatedText}/>
                <Route path="/mockhtml/t_autosuggest" exact component={t_AutoSuggest}/>
                <Route path="/mockhtml/t_basichtml" exact component={t_BasicHTML}/>
                <Route path="/mockhtml/t_topmenu" exact component={t_MenuPage}/>
                <Route path="/mockhtml/t_boxanimate" exact component={T_BoxAnimate}/>
                <Route path = '/mockhtml/t_datepicker' exact component={T_BasicDatePicker}/>
                <Route path = '/mockhtml/t_table1' exact component={T_ReactTable}/>
                <Route path = '/mockhtml/t_linechart' exact component={T_LineChart}/>
                
                <Route path = '/mocksite/t_lottery' exact component={T_Lottery}/>
                <Route path ='/mocksite/t_lottery_horse' exact component={T_LotteryHorse} />
                <Route path = '/mocksite/t_googlesearch' exact component={T_GoogleSearch}/>
                <Route path = '/mocksite/t_tictactoe' exact component={T_TicTacToe}/>
                {/* <Route path ='/mocksite/t_ecommerce' exact component={T_ECommerce} />
                <Route path ='/mocksite/t_ecommerce/items' exact component={T_ECommerceItems} />
                <Route path ='/mocksite/t_ecommerce/payment' exact component={T_ECommercePayment} />
                <Route path ='/mocksite/t_ecommerce/thank' exact component={T_ECommerceThankYou} />  */}
                <Route path ='/mocksite/t_bank' exact component={T_Bank} />
                <Route path ='/mocksite/t_bankaccount' exact component={T_BankAccount} />
                <Route path ='/mocksite/t_travel' exact component={T_Travel} />
                <Route path ='/mocksite/t_travelresult' exact component={T_TravelResult} />
                <Route path ='/mocksite/t_travelreserve' exact component={T_TravelReserve} />
                <Route path ='/mocksite/t_travelthank' exact component={T_TravelThankYou} /> 


                <Route path="/redirect" exact component={RedirectPage}/>



                

                {/* <Route path="/counsellor" exact component={Counsellor}/> */}
            </div>
        </Suspense>
    )
}

export default RoutePage